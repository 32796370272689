export interface LocalStorageInterface {
  // Auth tokens
  getRefreshToken: () => string | null
  hasRefreshToken: () => boolean
  getIDToken: () => string | null
  storeTokens: (refreshToken: string | null, idToken: string | null) => void
  clearAuthTokens: () => void

  // Flags
  setLocalStorageFlag: (flag: string) => void
  setNewUserLocalStorageFlags: () => void

  setGuideStepCompleted: (step: number) => void
  hasCompletedGuideStep: (step: number) => boolean
}

export const localStorageItems = {
  refreshToken: "REFRESH",
  idToken: "TOKEN",
  displayGetStarted: "displayGetStarted",
  needsToSeeTutorial: "needsToSeeTutorial",
  needsToInviteClient: "needsToInviteClient",
  needsToFireRegistrationEvent: "needsToFireRegistrationEvent",
  hasSeenInviteModal: "hasSeenInviteModal",
  hasCompletedTrialCancelSurvey: "hasCompletedTrialCancelSurvey",
  hasSeenReleaseNotes_20240919: "hasSeenReleaseNotes_20240919",
}

export const sessionManager: LocalStorageInterface = {
  // TOKENS
  getRefreshToken: () => localStorage.getItem(localStorageItems.refreshToken),
  hasRefreshToken: () => !!localStorage.getItem(localStorageItems.refreshToken),
  getIDToken: () => localStorage.getItem(localStorageItems.idToken),
  storeTokens: (refreshToken: string | null, idToken: string | null) => {
    refreshToken &&
      localStorage.setItem(localStorageItems.refreshToken, refreshToken)
    idToken && localStorage.setItem(localStorageItems.idToken, idToken)
  },
  clearAuthTokens: () => {
    localStorage.removeItem(localStorageItems.refreshToken)
    localStorage.removeItem(localStorageItems.idToken)
  },

  // FLAGS
  setLocalStorageFlag: (flag: string) => localStorage.setItem(flag, "true"),
  setNewUserLocalStorageFlags: function (this: LocalStorageInterface) {
    this.setLocalStorageFlag(localStorageItems.needsToSeeTutorial)
    this.setLocalStorageFlag(localStorageItems.needsToInviteClient)
    this.setLocalStorageFlag(localStorageItems.needsToFireRegistrationEvent)
    this.setLocalStorageFlag(localStorageItems.displayGetStarted)
  },

  setGuideStepCompleted: (step: number) =>
    localStorage.removeItem(`ONBOARD_STEP_${step}`),
  hasCompletedGuideStep: (step: number) =>
    !localStorage.getItem(`ONBOARD_STEP_${step}`),
}
