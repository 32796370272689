import {
  FlexBox,
  Modal,
  SPACING,
  Typography,
  JoonUIColor,
  Button,
  Shadow,
  TextArea,
  TextButton,
  CloseIcon,
} from "@joonapp/web-shared"
import { useEffect, useRef, useState } from "react"

import {
  CancelledTrialModalStep,
  useCancelledTrialModalStore,
} from "./useCancelledTrialModalStore"
import useSubscription from "./useSubscription"
import { getStripeBillingUrl } from "../../networking/practice"
import { usePracticeQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

const CancelledTrialModal = () => {
  const { isOpen, onClose, step } = useCancelledTrialModalStore()

  return (
    <Modal isOpen={isOpen} onClose={onClose} mandatory>
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: SPACING.space4,
          right: SPACING.space4,
        }}
      >
        <CloseIcon color={JoonUIColor.text.primary} />
      </button>
      <FlexBox
        direction="column"
        style={{ padding: SPACING.space6, width: "min(400px, 100vw)" }}
      >
        {step === CancelledTrialModalStep.MANAGE_OR_CANCEL && (
          <ManageOrCancel />
        )}
        {step === CancelledTrialModalStep.CANCELLATION_REASON && (
          <CancellationReasonQuestion />
        )}
        {step === CancelledTrialModalStep.ALTERNATIVE_SOLUTIONS && (
          <AlternativeSolutions />
        )}
      </FlexBox>
    </Modal>
  )
}

export default CancelledTrialModal

enum CancellationReason {
  COULDNT_FIGURE_OUT_HOW_TO_USE_IT = "Couldn't figure out how to use it",
  PARENTS_NEVER_ACCEPTED_MY_INVITE = "Parents never accepted my invite",
  PARENTS_DIDNT_LIKE_USING_IT = "Parents didn't like using it",
  TOO_EXPENSIVE = "Too expensive",
  RESOURCE_LIBRARY_MISSING_CONTENT = "Resource library was missing content",
  WASNT_COLLECTING_THE_RIGHT_DATA = "Wasn't collecting the right data",
  OTHER = "Other (with fill in the blank)",
}

const ManageOrCancel = () => {
  const { subscriptionText } = useSubscription()
  const { practice } = usePracticeQuery()
  const [isLoading, setIsLoading] = useState(false)
  const { setStep } = useCancelledTrialModalStore()

  const onClickManage = async () => {
    if (!practice || isLoading) return
    setIsLoading(true)
    const response = await getStripeBillingUrl(practice?.id)
    window.open(response, "_blank")
    trackAnalyticEvent(ANALYTIC_EVENTS.settings_billing_link)

    setIsLoading(false)
  }

  return (
    <FlexBox direction="column" gap={SPACING.space6}>
      <FlexBox direction="column" gap={SPACING.space2}>
        <Typography variant="h3">Update payment details</Typography>
        <Typography variant="bodySmall">{subscriptionText}</Typography>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space2}>
        <Button
          buttonType="primary"
          onClick={onClickManage}
          text="Manage subscription"
          fullWidth
        />
        <TextButton
          onClick={() => setStep(CancelledTrialModalStep.CANCELLATION_REASON)}
          style={{ borderRadius: SPACING.space2, width: "100%" }}
        >
          <Typography variant="caption" color={JoonUIColor.text.secondary}>
            Cancel my subscription
          </Typography>
        </TextButton>
      </FlexBox>
    </FlexBox>
  )
}

const CancellationReasonQuestion = () => {
  const { setStep } = useCancelledTrialModalStore()
  const [selectedReason, setSelectedReason] = useState<string>("")
  const [otherReason, setOtherReason] = useState<string>("")
  const otherReasonInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.trial_cancellation_survey_seen)
  }, [])

  const handleSelectReason = (reason: string) => {
    setSelectedReason(reason)
  }

  const handleNext = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.trial_cancellation_reason, {
      answer: selectedReason,
    })
    setStep(CancelledTrialModalStep.ALTERNATIVE_SOLUTIONS)
  }

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <Typography variant="h3">Why didn't Joon work for you?</Typography>
      <FlexBox direction="column" gap={SPACING.space2}>
        {Object.values(CancellationReason).map((reason) => (
          <CancellationReasonButton
            reason={reason}
            selectedReason={selectedReason}
            handleSelectReason={handleSelectReason}
          />
        ))}
        {selectedReason === CancellationReason.OTHER && (
          <input
            name="other-reason"
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            placeholder="Please explain"
            style={{
              borderRadius: SPACING.space2,
              border: `1px solid ${JoonUIColor.border.default}`,
              padding: `${SPACING.space3} ${SPACING.space3}`,
              width: "100%",
              textAlign: "left",
            }}
            ref={otherReasonInputRef}
            // eslint-disable-next-line
            autoFocus
            maxLength={120}
          />
        )}
      </FlexBox>
      <Button
        buttonType="primary"
        onClick={handleNext}
        text="Next"
        disabled={
          selectedReason === "" ||
          (selectedReason === CancellationReason.OTHER && otherReason === "")
        }
        style={{
          borderRadius: SPACING.space2,
          margin: "auto",
          marginTop: SPACING.space4,
          width: "200px",
        }}
      />
    </FlexBox>
  )
}

const CancellationReasonButton = ({
  reason,
  selectedReason,
  handleSelectReason,
}: {
  reason: string
  selectedReason: string
  handleSelectReason: (reason: string) => void
}) => {
  const isSelected = selectedReason === reason
  const [isHovered, setIsHovered] = useState(false)

  return (
    <button
      key={reason}
      onClick={() => handleSelectReason(reason)}
      style={{
        border: `1px solid ${
          isSelected ? JoonUIColor.border.accent : JoonUIColor.border.default
        }`,
        borderRadius: SPACING.space2,
        padding: `${SPACING.space3} ${SPACING.space3}`,
        width: "100%",
        textAlign: "left",
        backgroundColor: isSelected
          ? JoonUIColor.background.lightAccent
          : isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        boxShadow: isSelected ? "" : Shadow.low,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Typography variant="bodySmall" style={{ fontWeight: "600" }}>
        {reason}
      </Typography>
    </button>
  )
}

const AlternativeSolutions = () => {
  const [alternativeSolution, setAlternativeSolution] = useState<string>("")

  const { practice } = usePracticeQuery()
  const [isLoading, setIsLoading] = useState(false)

  const routeToCancellation = async () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.trial_cancellation_alternative, {
      answer: alternativeSolution,
    })

    if (!practice || isLoading) return
    setIsLoading(true)
    const response = await getStripeBillingUrl(practice?.id)
    window.open(response, "_blank")
    trackAnalyticEvent(ANALYTIC_EVENTS.settings_billing_link)

    setIsLoading(false)
  }

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <Typography variant="h3">
        What will you use going forward to conduct parent training and increase
        parent buy-in?
      </Typography>
      <TextArea
        name="alternative-solution"
        fullWidth
        value={alternativeSolution}
        onChange={(e) => setAlternativeSolution(e.target.value)}
        placeholder="e.g. We'll use..."
        maxLength={255}
      />
      <Button
        buttonType="primary"
        onClick={routeToCancellation}
        text="Next"
        disabled={alternativeSolution === ""}
        style={{
          borderRadius: SPACING.space2,
          margin: "auto",
          marginTop: SPACING.space4,
          width: "200px",
        }}
      />
    </FlexBox>
  )
}
