import {
  FlexBox,
  JoonUIColor,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import { useCancelledTrialModalStore } from "./useCancelledTrialModalStore"
import useSubscription from "./useSubscription"
import useAdminUser from "../../pages/settings/useAdminUser"

const SubscriptionSection = () => {
  const { subscriptionText } = useSubscription()

  const { onOpen: openCancelSubscriptionModal } = useCancelledTrialModalStore()
  const isMobile = useMediaQuery("(max-width: 800px)")
  const isAdminUser = useAdminUser()

  return (
    <FlexBox direction="column" gap={SPACING.space4}>
      <Typography variant="h3">Subscription</Typography>
      <FlexBox
        direction={isMobile ? "column" : "row"}
        align={isMobile ? "flex-start" : "center"}
        wrap={false}
        justify="space-between"
        style={{
          padding: SPACING.space4,
          borderRadius: SPACING.space4,
          background: JoonUIColor.background.primaryNeutral,
          width: "100%",
          border: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <Typography variant="body">{subscriptionText}</Typography>
        {isAdminUser && (
          <TextButton onClick={openCancelSubscriptionModal}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Manage subscription
            </Typography>
          </TextButton>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default SubscriptionSection
