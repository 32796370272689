import { create } from "zustand"

interface CancelledTrialModalStore {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  step: CancelledTrialModalStep
  setStep: (step: CancelledTrialModalStep) => void
}

export enum CancelledTrialModalStep {
  MANAGE_OR_CANCEL,
  CANCELLATION_REASON,
  ALTERNATIVE_SOLUTIONS,
  THANKS,
}

export const useCancelledTrialModalStore = create<CancelledTrialModalStore>()(
  (set) => ({
    isOpen: false,
    onClose: () =>
      set({ isOpen: false, step: CancelledTrialModalStep.MANAGE_OR_CANCEL }),
    onOpen: () => set({ isOpen: true }),
    step: CancelledTrialModalStep.MANAGE_OR_CANCEL,
    setStep: (step: CancelledTrialModalStep) => set({ step }),
  })
)
