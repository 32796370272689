import { Button, FlexBox, SPACING, Typography } from "@joonapp/web-shared"
import { useMediaQuery } from "@mui/material"

import { useAddEditQuestModalStore } from "./weeklyProgress/useAddEditQuestModalStore"
import OpenPDFButton from "../../../components/buttons/OpenPDFButton"
import Squiggle from "../../../components/squiggle/Squiggle"
import { PatientInfoTab } from "../../../types"

const HomeRoutinesEmptyState = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")
  const { onOpen: openAddQuest } = useAddEditQuestModalStore()

  return (
    <FlexBox
      direction="column"
      align="center"
      justify="center"
      gap={SPACING.space12}
      style={{ marginTop: SPACING.space2 }}
    >
      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space6}
      >
        <Typography
          variant={isMobile ? "h2" : "h1"}
          textAlign="center"
          style={{ width: "min(600px, 90%)" }}
        >
          Welcome to Home Routines
        </Typography>
        <Typography
          variant="bodySmall"
          style={{ width: "min(600px, 90%)" }}
          textAlign="center"
        >
          Assign your clients specific routines they can work on at home with
          their parent. Parents can add to the client's routine within the
          Quests tab of the Parent App.
        </Typography>
        <Button
          onClick={() => openAddQuest()}
          text="+ Add routine"
          style={{ whiteSpace: "nowrap" }}
        />
      </FlexBox>

      <FlexBox
        direction="column"
        align="center"
        justify="center"
        gap={SPACING.space8}
      >
        <FlexBox
          direction="row"
          align="center"
          gap={SPACING.space4}
          justify="center"
        >
          <Squiggle />
          <Typography variant="h3">How this works</Typography>
          <Squiggle />
        </FlexBox>
        <FlexBox
          direction="column"
          align="center"
          justify="center"
          gap={SPACING.space4}
        >
          <Typography variant="bodyBold">
            Add client specific routines they can work on at home with their
            parents and monitor consistencies
          </Typography>
          <img
            src="/images/homeRoutinesEmpty1.svg"
            alt="Therapist view for home routines"
            style={{ width: "min(800px, 100%)" }}
          />
          <OpenPDFButton
            text="Preview in sample patient"
            imageUrl={`/patients/0/${PatientInfoTab.HOME_ROUTINES}`}
          />
        </FlexBox>
        <FlexBox
          direction={isMobile ? "column" : "row"}
          wrap={false}
          gap={isMobile ? SPACING.space6 : SPACING.space12}
          justify="center"
          align={isMobile ? "center" : "flex-start"}
        >
          <FlexBox
            direction="column"
            align="center"
            justify="center"
            gap={SPACING.space4}
            style={{ width: "min(300px, 90%)" }}
          >
            <Typography variant="bodyBold" textAlign="center">
              Parents can easily manage their child’s routine at home
            </Typography>
            <img
              src="/images/homeRoutinesEmpty2.svg"
              alt="Parent view for Quest Board"
              style={{ marginBottom: `-${SPACING.space2}` }}
            />
            <OpenPDFButton
              text="Preview parent experience"
              imageUrl="https://apps.apple.com/us/app/joon-behavior-improvement-app/id1482225056"
            />
          </FlexBox>
          <FlexBox
            direction="column"
            align="center"
            justify="center"
            gap={SPACING.space4}
            style={{ width: "min(360px, 90%)" }}
          >
            <Typography variant="bodyBold" textAlign="center">
              Optionally, applicable clients are able to keep track of their own
              routines via the Joon Kid App
            </Typography>
            <img
              src="/images/homeRoutinesEmpty3.svg"
              alt="Child Joon Kid App view for home routines"
              style={{ width: "100%" }}
            />
            <OpenPDFButton
              text="Preview child experience"
              imageUrl="https://apps.apple.com/us/app/joon-pet-game/id1631871208" // TODO: Add child experience PDF
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default HomeRoutinesEmptyState
