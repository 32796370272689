import AddPatientModal from "./AddPatientModal"
import BAAModal from "./BAAModal"
import DatePickerModal from "./DatePickerModal"
import { InvitationAcceptModal } from "./InvitationAcceptModal"
import OnboardModal from "./OnboardModal"
import ReleaseNotesModal from "./ReleaseNotesModal"
import { WebinarModal } from "../../pages/patientsDashboard/Webinar"
import CancelledTrialModal from "../subscription/CancelledTrialModal"
import PaywallModal from "../subscription/PaywallModal"
import SubscriptionExpiredModal from "../subscription/SubscriptionExpiredModal"

const ModalProvider = () => {
  return (
    <>
      <ReleaseNotesModal />
      <OnboardModal />
      <DatePickerModal />
      <WebinarModal />
      <InvitationAcceptModal />
      <AddPatientModal />
      <BAAModal />
      <PaywallModal />
      <SubscriptionExpiredModal />
      <CancelledTrialModal />
    </>
  )
}

export default ModalProvider
